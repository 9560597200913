import React, { FC } from 'react';
import classNames from 'classnames';

import {
  Position,
  InjectedTranslateProps,
  InjectedClassNameProps,
} from '../../../types';
import Tooltip from '../Tooltip';
import styles from './Icon.scss';

const TOOLTIP_TIMEOUT = 250;

interface IconProps extends InjectedClassNameProps, InjectedTranslateProps {
  className?: string;
  iconClassName?: string;
  icon: any;
  tooltip?: string;
  placement?: Position;
}

const Icon: FC<IconProps> = ({
  className,
  iconClassName,
  icon,
  tooltip,
  placement,
  t,
}) => {
  const content = (
    <span className={classNames(iconClassName, styles.svgContainer)}>
      {icon}
    </span>
  );

  return tooltip ? (
    <Tooltip text={t(tooltip)} placement={placement} timeout={TOOLTIP_TIMEOUT}>
      {content}
    </Tooltip>
  ) : (
    <div className={classNames(styles.container, className)}>{content}</div>
  );
};

export default Icon;
